import React from 'react';

const DataTable = ({ data, columns, columnMapping }) => {
  const formatCell = (column, value) => {
    if (column === 'Genes_incl_biomarkers' || column === 'Targets') {
      if (typeof value === 'string' && value.startsWith('[') && value.endsWith(']')) {
        try {
          const parsedArray = JSON.parse(value.replace(/'/g, '"'));
          return parsedArray.join(', ');
        } catch (error) {
          console.error(`Error parsing ${columnMapping[column]}:`, error);
          return value;
        }
      } else if (Array.isArray(value)) {
        return value.join(', ');
      }
    }
    return value;
  };

  // Sort the data by Abstract Number
  const sortedData = [...data].sort((a, b) => {
    const aNum = parseInt(a.Abstract_Number, 10);
    const bNum = parseInt(b.Abstract_Number, 10);
    return aNum - bNum;
  });

  return (
    <div className="data-table">
      <table>
        <thead>
          <tr>
            {columns.map(column => (
              <th key={column}>{columnMapping[column]}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, index) => (
            <tr key={index}>
              {columns.map(column => (
                <td key={column}>{formatCell(column, row[column])}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
